














import { Component, Vue, Prop } from 'vue-property-decorator'
import ButtonLink from '@/components/atoms/quiz/ButtonLink.vue'
import IconMark from '@/components/atoms/quiz/IconMark.vue'
import { AnswerStoreType } from '@/types/quiz/units'

@Component({
  components: {
    ButtonLink,
    IconMark,
  },
})
export default class ResultItem extends Vue {
  @Prop({ default: 1 })
  private order?: number

  @Prop({ default: false })
  private isMark?: boolean

  @Prop({ default: false })
  private isCorrect?: boolean

  @Prop({ default: {} })
  private answer!: AnswerStoreType

  private get listInfo() {
    const correctQuestion: any = this.answer?.answers?.find?.((item) => item.isCorrectText) || {}
    return [
      {
        textLeft: '問題',
        textRight: this.answer?.questionTextContent,
      },
      {
        textLeft: 'あなたの回答',
        textRight: this.answer?.yourChoose?.choiceText,
      },
      {
        textLeft: '正しい答え',
        textRight: correctQuestion?.choiceText,
      },
      ...(this.answer?.questionTextExplain
        ? [
            {
              textLeft: '解説',
              textRight: this.answer?.questionTextExplain,
            },
          ]
        : []),
    ]
  }

  private onClickMark() {
    this.$emit('mark', this.answer)
  }
}
