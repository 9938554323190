








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconMark extends Vue {
  @Prop({default: false})
  private isMark!: boolean

  private onClick(){
    this.$emit('click')
  }
}
