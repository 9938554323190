










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Process extends Vue {
  @Prop({default: 0})
  private total!: number

  @Prop({default: 0})
  private current!: number

  private get textLeft() {
    return `${this.current}/${this.total}`
  }

  private get percent() {
    return `${Math.floor((this.current/this.total)*100)}`
  }

  private close(){
    this.$emit('close')
  }
}
