























import { Component, Mixins, Vue } from 'vue-property-decorator'
import Process from '@/components/atoms/quiz/Process.vue'
import ResultItem from '@/components/molecules/quiz/ResultItem.vue'
import ButtonNext from '@/components/atoms/quiz/ButtonNext.vue'
import { AnswerStoreType, AnswerType, QuestionCode } from '@/types/quiz/units'
import UnitQuiz from '@/mixins/quiz/UnitQuiz'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
@Component({
  components: {
    Process,
    ResultItem,
    ButtonNext,
  },
})
export default class ResultDrill extends Mixins(UnitQuiz, BaseQuiz) {
  private get titleQuestion() {
    return this.$store.getters['question/title']
  }

  private get totalFlashCard() {
    return this.$store.getters['question/totalFlashCard']
  }

  private get totalQuestion() {
    return this.$store.getters['question/totalQuestion'] - this.totalFlashCard
  }

  private get numberCorrectQuestion() {
    return this.$store.getters['question/correctCount'] - this.totalFlashCard
  }

  private get answers() {
    const listAnswers = this.$store.getters['question/answers'] as AnswerStoreType[]
    return listAnswers.filter(item => item.answerFormatCode !== QuestionCode.FLASHCARD)
  }

  private get cup() {
    const percent = Math.floor((this.numberCorrectQuestion / this.totalQuestion) * 100)

    if (percent > 66) {
      return '/quiz-assets/imgs/cup-gold.png'
    }
    if (percent > 33 && percent <= 66) {
      return '/quiz-assets/imgs/cup-silver.png'
    }

    return '/quiz-assets/imgs/cup-bronze.png'
  }

  private checkAnswerCorrect(answer: AnswerType){
    return answer.isCorrectText
  }

  private nextPage() {
    const submitSuccess = this.$store.getters['question/submitSuccess']
    if (submitSuccess === 'pendding') {
      return
    }
    if (submitSuccess) {
      this.push('/quiz/overview', this.$store.getters['question/title'] + '出題')
    } else {
      this.goTopPage()
    }
  }
}
